// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  width: "100%";
  height: "100vh";
}

.Error_main__ZByJn {
  background-color: white;
  padding: 80px;
}
.Error_main__ZByJn h1 {
  font-size: 80px;
  font-weight: 800;
  color: #1b4e9b;
}
.Error_main__ZByJn h3 {
  font-size: 30px;
}
.Error_main__ZByJn button {
  background-color: #bccf31;
  color: white;
  border: none;
  border-radius: 7px;
  padding: 7px 35px;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/views/Error.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;AACF;;AAEA;EACE,uBAAA;EACA,aAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AACJ;AAEE;EACE,eAAA;AAAJ;AAGE;EACE,yBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,gBAAA;AADJ","sourcesContent":["* {\n  width: \"100%\";\n  height: \"100vh\";\n}\n\n.main {\n  background-color: #ffff;\n  padding: 80px;\n\n  h1 {\n    font-size: 80px;\n    font-weight: 800;\n    color: #1b4e9b;\n  }\n\n  h3 {\n    font-size: 30px;\n  }\n\n  button {\n    background-color: #bccf31;\n    color: white;\n    border: none;\n    border-radius: 7px;\n    padding: 7px 35px;\n    font-weight: 600;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Error_main__ZByJn`
};
export default ___CSS_LOADER_EXPORT___;
